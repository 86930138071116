.filter {
    border-width: 0px 1px;
    border-style: solid;
    border-color: #543f3f;
    padding: 0 15px;
}

.filter> :first-child {
    max-width: 60px;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 13px 19px;
    align-items: center;
    margin: 0 0 0 auto;
    cursor: pointer;
}

.filter> :first-child img {
    width: 12px;
}

.filter> :first-child p {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    margin: 0;
    color: #ffffff;
}

.pricefilter {
    margin-top: 55px;
}

.pricefilter>p {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #ffffff;
}

.inputContainer {
    display: flex;
    justify-content: space-between;
}

.inputContainer input {
    height: 35px;
    width: 85px;
    border: 1px solid #543f3f;
    border-radius: 10px;
    background: #17121d;
    padding: 0 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
}

.inputContainer input::placeholder {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #543f3f;
}

.pricefilter>button {
    background: #0F18CB;
    box-shadow: 0px 0px 45px #5151E5, inset 0px 0px 20px #9E9E9E;
    border-radius: 12px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    /* identical to box height, or 200% */
    text-align: center;
    letter-spacing: -0.014em;
    color: #ffffff;
    cursor: pointer;
    margin-top: 25px;
    position: relative;
    overflow: hidden;
    margin-top: 25px;
    border: none;
}

.pricefilter>button::before {
    content: "";
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    transform: translateX(-100%) rotate(45deg);
    transition: all 0.6s;
}

.pricefilter>button:hover::before {
    transform: translateX(100%) rotate(45deg);
}

@media only screen and (min-width: 766px) {
    .filter {
        min-height: 50vh;
    }
}