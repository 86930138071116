.header {
    width: 90%;
    margin: 25px auto;
    display: grid;
    grid-template-columns: 200px 1fr;
    max-width: 1300px;
    position: relative;
}

.logoflex {
    display: flex;
    gap: 4px;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.logoflex img {
    width: 65px;
}

.header> :first-child {
    font-family: 'Neue Machina' !important;
}

.header a,
.header a:visited {
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 27px;
    /* identical to box height */
    letter-spacing: -0.014em;
    cursor: pointer;
    user-select: none;
    color: #ef114a;
    text-decoration: none;
}

.header> :last-child ul {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-direction: column;
    padding-left: 5px;
    margin: 15% 0 15% 0;
}

.header> :last-child ul a,
.header> :last-child ul a:visited {
    list-style: none;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    cursor: pointer;
    color: #eeeeee;
    text-decoration: none;
}

.header> :last-child ul a:hover,
.active {
    color: #ef114a !important;
}

.menu {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    z-index: 1;
    background: linear-gradient(219.87deg, #0f0f18 36.97%, #12124d 98.61%);
    transition: all 500ms ease-in-out;
    opacity: 0;
    display: block;
    width: 100%;
    max-width: 320px;
    align-items: center;
    gap: 80px;
    justify-items: flex-end;
    padding: 0 5%;
}

.show {
    left: 0;
    opacity: 1;
}

.mobheader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.mobheader img,
.header>img {
    width: 22px;
    object-fit: contain;
    justify-self: flex-end;
}

.hide {
    display: none;
}

@media only screen and (min-width: 750px) {
    .header>img {
        display: none;
    }
    .header> :last-child ul {
        flex-direction: row;
        display: flex;
        justify-content: center;
        gap: 80px;
        align-items: center;
        margin: 0;
    }
    .menu {
        position: sticky;
        max-width: none;
        display: grid;
        grid-template-columns: 2fr max-content;
        align-items: center;
        gap: 80px;
        justify-items: flex-end;
        opacity: 1;
        height: auto;
        background: transparent;
        padding: 0 !important;
    }
    .mobheader {
        display: none;
    }
}