.card {
    display: flex;
    gap: 2%;
    max-width: 1000px;
    margin: 35px auto;
    align-items: flex-start;
    width: 90%;
    flex-wrap: wrap;
}

.card>div {
    border-radius: 12px;
}

.card img {
    width: 180px;
    border-radius: 7px;
}

.card p {
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 15px;
    text-align: justify;
    color: #e1e1e1;
    margin-top: 10px;
}

.top {
    font-weight: 800 !important;
    color: #d2d2d2 !important;
}

.card span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    color: #9f9f9f;
    margin-top: 10px;
    display: block;
}

@media only screen and (min-width: 600px) {
    .card {
        flex-wrap: nowrap;
    }
}