.card {
  border-radius: 12px;
  width: 280px;
  padding: 20px;
}

.card > img {
  width: 280px;
}

.name {
  display: flex;
  gap: 6px;
  align-items: center;
}

.name p {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.014em;

  color: #ffffff;
}

.name img {
  width: 15px;
}

.transCard {
  margin-top: 7px;
  padding: 25px 15px 8px 15px;
  background: linear-gradient(
    116.79deg,
    rgba(255, 255, 255, 0.48) 0%,
    rgba(255, 255, 255, 0.12) 99.45%
  );
  backdrop-filter: blur(12.5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d0ccde;
}

.transCard > div > span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.014em;

  color: #dcdcdc;
}

.transCard > div > div {
  display: flex;
  gap: 6px;
  align-items: center;
}

.transCard > div > div p {
  margin: 5px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.014em;

  color: #ffffff;
}

.transCard > div > div img {
  width: 15px;
}
