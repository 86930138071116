.container {
    max-width: 150px;
    border: 1px solid #543f3f;
    border-radius: 12px;
    cursor: pointer;
}

.details {
    background: #16151c;
    border-radius: 0 0 12px 12px;
    padding: 5%;
}

.container>img {
    width: 100%;
}

.details {
    display: flex;
    justify-content: space-between;
}

.details> :first-child> :first-child {
    display: flex;
    gap: 4px;
    align-items: center;
}

.details> :first-child> :first-child p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    margin: 0;
    color: #ffffff;
}

.details> :first-child img {
    width: 13px;
}

.details> :first-child>span {
    display: block;
    margin-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.price span {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.price>div {
    display: flex;
    gap: 4px;
    align-items: flex-start;
}

.price>div p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.014em;
    margin-top: 7px;
    color: #ffffff;
}

.price>div img {
    width: 15px;
    margin-top: 7px;
}