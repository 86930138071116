.token {
  border-right: 2px solid #543f3f;
  padding: 0 15px;
}

.token > p {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.014em;
  margin-bottom: 35px;
  color: #ffffff;
}

.token > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.token > div span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  text-transform: capitalize;
  letter-spacing: -0.014em;

  color: #dcdcdc;
}

.token > div > p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: +0.014em;

  color: #ffffff;
}
