.card {
    position: fixed;
    top: 20%;
    left: calc(50% - 160px);
    border-radius: 12px;
    width: 320px;
    padding: 20px;
    background: radial-gradient(53.19% 53.19% at 50% 46.81%, #110E34 0%, #000000 100%);
}

.card>img {
    width: 320px;
}

.name {
    display: flex;
    gap: 6px;
    align-items: center;
}

.name p {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #ffffff;
}

.name img {
    width: 15px;
}

.transCard {
    margin-top: 7px;
    padding: 15px 15px 8px 15px;
    background: linear-gradient( 116.79deg, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.12) 99.45%);
    backdrop-filter: blur(12.5px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 15px;
    border: 1px solid #d0ccde;
}

.transCard p {
    margin-top: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #FFFFFF;
}

.transCard button {
    background: radial-gradient(53.19% 53.19% at 50% 46.81%, #110E34 0%, #000000 100%);
    border: none;
    padding: 7px 15px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #FFFFFF;
    cursor: pointer;
}

.transCard>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}