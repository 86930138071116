.container {
  max-width: 350px;
}

.flex {
  overflow-x: scroll;
  display: flex;
  gap: 25px;
}

.flex::-webkit-scrollbar {
  display: none;
}

.control {
  display: flex;
  gap: 7px;
  margin-top: 25px;
  justify-content: center;
}

.control > div {
  width: 21px;
  height: 6px;
  cursor: pointer;
  background: #382626;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}

.active {
  width: 41px !important;
  height: 6px;
  background: #ffffff !important;
}

@media only screen and (min-width: 730px) {
  .container {
    max-width: 100%;
  }
}
