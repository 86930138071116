.table div {
    display: flex;
    gap: 50px;
    align-items: center;
}

.whitelist>div {
    margin: 60px 0;
}

.whitelist button {
    display: block;
    font-size: 16px;
    font-weight: 600 !important;
    cursor: pointer;
    padding: 6px 15px;
    border-radius: 5px;
    border: none;
    margin: 10px 0;
}

.whitelist input {
    height: 30px;
    border-radius: 5px;
    width: 300px;
    font-size: 14px;
    font-weight: 600 !important;
    padding-left: 10px;
    border: none;
}

.whitelist h3 {
    font-size: 15px;
}