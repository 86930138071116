.casinoImg {
    width: 100%;
    margin-top: -100px;
}

.casino {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    flex-wrap: wrap;
    padding: 0 15px 85px 15px;
    align-items: center;
}

.casino>img {
    width: 100%;
}

.casino>div {
    max-width: 480px;
    justify-self: center;
}

.casino>div h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.014em;
    color: #dad7d8;
    margin-bottom: 20px;
    text-shadow: 8px 2px 60px rgba(243, 18, 62, 0.8);
}

.casino> :first-child img {
    display: block;
    width: 270px;
}

.casino>div p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* or 160% */
    letter-spacing: -0.014em;
    color: #7f7f91;
}

@media only screen and (min-width: 700px) {
    .casino {
        margin-top: -65px;
        padding: 0 15px 85px 15px;
    }
    .casino>div h2 {
        font-size: 60px;
        line-height: 80px;
    }
    .casino>div p {
        font-size: 20px;
        line-height: 28px;
    }
}

@media only screen and (min-width: 1000px) {
    .casino {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
    }
    .casino>div {
        margin-top: 10%;
    }
}