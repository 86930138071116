.header {
    display: flex;
    justify-content: space-between;
}

.header>button {
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 10px;
    padding: 8px 12px;
    cursor: pointer;
    opacity: 0;
}

.header>button img {
    width: 12px;
}

.none {
    display: none;
}

.filter {
    position: relative;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 10px;
    width: 100%;
    max-width: 50px;
    padding: 10px 12px;
}

.filter> :first-child {
    display: grid;
    grid-template-columns: 1fr 12px;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.filter p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    width: -webkit-fill-available;
    color: #ffffff;
    margin: 0;
}

.filter> :first-child img {
    width: 12px;
}

.dropDown {
    position: absolute;
    top: 55px;
    width: 65px;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 8px;
    padding: 3px;
    top: 48px;
    right: 0;
    opacity: 1;
    transition: all 500ms ease-in-out;
    display: none;
}

.dropDown li {
    list-style: none;
    padding: 7px 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    width: -webkit-fill-available;
    color: #a7a7a7;
    user-select: none;
    cursor: pointer;
}

.show {
    display: block;
}

.empty {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    margin: 3px 0;
    color: #cacaca;
    text-align: center;
    grid-column: 1/5;
}