.buttonContainer {
    justify-self: flex-end;
}

.buttonContainer>button {
    background: #0F18CB;
    box-shadow: 0px 0px 45px #5151E5, inset 0px 0px 20px #9E9E9E;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 200% */
    cursor: pointer;
    text-align: center;
    letter-spacing: -0.014em;
    padding: 4px 28px;
    color: #ffffff;
    position: relative;
    overflow: hidden;
}

.buttonContainer button::before {
    content: "";
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    transform: translateX(-100%) rotate(45deg);
    transition: all 0.6s;
}

.buttonContainer button:hover::before {
    transform: translateX(100%) rotate(45deg);
}

.connected {
    display: flex;
    max-width: 50px;
    gap: 10px;
    padding: 6px 14px;
    border: 1px solid #543f3f;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
}

.connected> :first-child {
    width: 25px;
}

.connected> :last-child {
    width: 15px;
    object-fit: contain;
}

.container {
    position: relative;
}

.container> :last-child {
    position: absolute;
    width: max-content;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 10px;
    padding: 3px;
    top: 48px;
    right: -500px;
    opacity: 0;
    transition: all 500ms ease-in-out;
}

.open {
    right: calc(100% - 140px) !important;
    opacity: 1 !important;
}

.grid {
    display: grid;
    grid-template-columns: 12px 1fr;
    list-style: none;
    align-items: center;
    gap: 18px;
    padding: 5px 24px;
    border-radius: 7px;
}

.grid:hover {
    cursor: pointer;
    background: #0F18CB;
    box-shadow: 0px 0px 45px #5151E5, inset 0px 0px 20px #9E9E9E;
}

.grid img {
    width: 12px;
}

.grid p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.014em;
    color: #ffffff;
}

@media only screen and (min-width: 750px) {
    .open {
        right: 0 !important;
        opacity: 1 !important;
    }
}