.footer {
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 1300px;
    margin: 75px auto 55px auto;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-bottom: 55px;
    border-bottom: 2px solid #543f3f;
    gap: 30px;
}

.links {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
}

.links>div a,
.links>div a:visited {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    /* identical to box height */
    text-decoration: none;
    letter-spacing: -0.014em;
    transition: all 300ms ease-in-out;
    color: #ffffff;
}

.links>div img {
    width: 10px;
}

.links>div a:hover {
    color: #ef114a;
}

.platform h4 {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: -0.014em;
    margin: 0;
    color: #ef114a;
}

.socials {
    margin-top: 30px;
    display: flex;
    gap: 35px;
}

.socials img {
    width: 14px;
}

.socials a {
    background-color: transparent;
    border: 1px solid #543f3f;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socials button:hover {
    box-shadow: 0px 0px 23px #0F18CB;
}

.footer> :last-child {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.footer> :last-child:hover p {
    color: #ef114a;
}

.footer> :last-child p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    margin: 0;
    letter-spacing: -0.014em;
    color: #ffffff;
}

.footer> :last-child img {
    width: 10px;
}