.hero {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 0 0 100px 0;
}

@media only screen and (min-width: 1000px) {
  .hero {
    display: grid;
    grid-template-columns: 1fr 500px;
    margin-top: 65px !important;
  }
}
