.hero {
    margin-top: 40px;
    margin-bottom: 40px;
    display: grid;
    gap: 60px;
}

.banner,
.details {
    max-width: 420px;
}

.banner>img {
    width: 100%;
    background: #424b5c;
    border-radius: 12px;
    filter: drop-shadow(3px 8px 60px rgba(255, 255, 255, 0.25));
}

.banner p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    /* or 160% */
    max-width: 380px;
    letter-spacing: -0.014em;
    color: #7f7f91;
}

.details {
    width: 100%;
}

.name {
    display: grid;
    grid-template-columns: 60px max-content 15px 1fr;
    gap: 7px;
    align-items: center;
}

.name> :first-child {
    width: 60px;
    background: #424b5c;
    border-radius: 12px;
    filter: drop-shadow(3px 8px 60px rgba(255, 255, 255, 0.25));
}

.name h4 {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 25px;
    /* identical to box height, or 100% */
    letter-spacing: -0.014em;
    color: #dad7d8;
    text-shadow: 8px 2px 60px rgba(243, 18, 62, 0.8);
}

.name> :nth-child(3) {
    width: 15px;
}

.name> :nth-child(4) {
    width: 20px;
    justify-self: flex-end;
    cursor: pointer;
}

.details>h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 48px;
    /* identical to box height */
    margin: 14px 0;
    letter-spacing: -0.014em;
    color: #ffffff;
}

.details>span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.price>div>div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.price>div>p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    margin: 5px 0;
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.price>div>span {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #7f7f91;
}

.price>div div>p {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 29px;
    /* identical to box height */
    margin: 4px 0;
    letter-spacing: -0.014em;
    color: #ffffff;
}

.price>div>div img {
    width: 15px;
}

.details>button {
    background: #0F18CB;
    box-shadow: 0px 0px 45px #5151E5, inset 0px 0px 20px #9E9E9E;
    border-radius: 8px;
    border: none;
    align-items: center;
    padding: 8px 35px;
    position: relative;
    overflow: hidden;
    margin-top: 45px;
    display: flex;
    justify-content: center;
    gap: 4px;
    width: 100%;
    cursor: pointer;
}

.details>button span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 200% */
    text-align: center;
    letter-spacing: -0.014em;
    color: #ffffff;
}

.details>button img {
    width: 15px;
}

.details>button::before {
    content: "";
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    transform: translateX(-100%) rotate(45deg);
    transition: all 0.6s;
}

.details>button:hover::before {
    transform: translateX(100%) rotate(45deg);
}

.form {
    margin-top: 25px;
}

.form>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form>div p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.form>div span {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #543f3f;
}

.form input {
    width: -webkit-fill-available;
    max-width: 100%;
    height: 52px;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 12px;
    padding: 0 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

@media only screen and (min-width: 1000px) {
    .hero {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 85px !important;
    }
    .banner p {
        font-size: 18px;
    }
    .details {
        justify-self: flex-end;
    }
}