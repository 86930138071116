.lowerContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 25px 0 100px 0;
  border-top: 2px solid #543f3f;
  margin: 70px 0 0 0;
}

@media only screen and (min-width: 766px) {
  .lowerContainer {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
  }
}
