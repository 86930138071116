.card {
  background: #17121d;
  border: 1px solid #543f3f;
  border-radius: 12px;
  padding: 15px 22px;
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  gap: 20px;
  margin: 25px 0;
  user-select: none;
}

.card > :first-child {
  width: 20px;
  display: none;
}

.card > :nth-child(2) {
  background: #424b5c;
  width: 50px;
  border-radius: 12px;
  filter: drop-shadow(3px 8px 60px rgba(255, 255, 255, 0.25));
}

.details > p {
  margin-top: 0;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: +0.014em;

  color: #ffffff;
}

.details span {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: +0.014em;

  color: #7f7f91;
}

.details span strong {
  color: #ffffff;
}

.details span img {
  width: 12px;
  margin-left: 3px;
}

.card > p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.014em;
  grid-column: 1/4;
  color: #ffffff;
  margin: 0;
}
@media only screen and (min-width: 450px) {
  .card {
    grid-template-columns: 20px 50px 1fr max-content;
    gap: 30px;
  }

  .card > :first-child {
    display: block;
  }

  .card > p {
    grid-column: 4/5;
    grid-row: 1;
  }
}
