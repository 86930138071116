.text {
    max-width: 380px;
}

.text h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    letter-spacing: -0.014em;
    margin: 5px 0;
    color: #e8e8e8;
    margin: 25px 0;
    text-shadow: 2px 2px 60px rgba(243, 18, 62, 0.6);
}

.text h1 span {
    background: linear-gradient(294.78deg, #0F18CB 37.28%, #FF2739 84.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 2px 2px 60px rgba(243, 18, 62, 0.6);
}

.text>p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    /* or 160% */
    margin: 25px 0;
    letter-spacing: -0.014em;
    color: #7f7f91;
}

.stats {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
}

.stats p {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    letter-spacing: -0.014em;
    margin-bottom: 15px;
    color: #ffffff;
}

.stats span {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.014em;
    color: #c2a6f2;
}

.pangsgif {
    display: block;
    width: 73px;
}

@media only screen and (min-width: 700px) {
    .text h1 {
        font-size: 46px;
        margin: 25px 0;
    }
    .text>p {
        font-size: 20px;
        line-height: 25px;
        margin: 25px 0;
    }
    .stats {
        gap: 35px;
        justify-content: start;
    }
    .stats p {
        font-size: 32px;
    }
    .stats span {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1000px) {
    .text {
        max-width: none;
    }
}