.activity {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 0 100px 0;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #543f3f;
  padding: 0 25px;
}
