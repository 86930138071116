.nfts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  flex-wrap: wrap;
  background: linear-gradient(
    227.91deg,
    #0f0f18 17.45%,
    #101032 53.48%,
    #0f0f18 96.33%
  );
  padding: 40px 15px 85px 15px;
}

.nfts > img {
  width: 100%;
}

.nfts > div {
  max-width: 480px;
  justify-self: center;
}

.nfts > div h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.014em;
  color: #dad7d8;
  margin-bottom: 20px;
  text-shadow: 8px 2px 60px rgba(243, 18, 62, 0.8);
}

.nfts > div p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* or 160% */
  letter-spacing: -0.014em;

  color: #7f7f91;
}
@media only screen and (min-width: 700px) {
  .nfts {
    background: linear-gradient(
      253.2deg,
      #0f0f18 27.86%,
      #0c0c3a 55.86%,
      #0f0f18 83.87%
    );
    padding: 40px 15px 125px 15px;
  }

  .nfts > div h2 {
    font-size: 60px;
    line-height: 80px;
  }

  .nfts > div p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (min-width: 1000px) {
  .nfts {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .nfts > div {
    margin-top: 10%;
  }
}
