.catalogue h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 50px;
    /* identical to box height, or 160% */
    text-align: center;
    color: #DAD7D8;
    text-shadow: 8px 2px 60px rgba(243, 18, 62, 0.8);
    margin-top: 45px;
}

@media only screen and (min-width: 600px) {
    .catalogue h2 {
        font-size: 50px;
    }
    .cardContainer> :nth-child(even) {
        flex-direction: row-reverse;
        align-items: center;
    }
}