.perks h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 60px;
    /* or 160% */
    text-align: center;
    color: #DAD7D8;
    text-shadow: 8px 2px 60px rgba(243, 18, 62, 0.8);
}

.perks h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    /* or 160% */
    text-align: center;
    color: #DAD7D8;
    text-shadow: 8px 2px 60px rgba(243, 18, 62, 0.8);
}

.perks>p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.014em;
    color: #DCDCDC;
    max-width: 900px;
    margin: 0 auto;
    width: 90%;
}

.perks>div {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    margin-top: 40px;
}

.perks>div p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.014em;
    color: #DCDCDC;
}

@media only screen and (min-width: 600px) {
    .perks h2 {
        font-size: 50px;
        line-height: 90px;
    }
    .perks>div p {
        max-width: 280px;
    }
}