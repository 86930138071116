body {
    margin: 0 auto;
    background: radial-gradient(53.19% 53.19% at 50% 46.81%, #110E34 0%, #000000 100%);
    max-width: 1300px;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Neue Machina";
    src: url("./fonts/NeueMachina-Regular.eot");
    src: url("./fonts/NeueMachina-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/NeueMachina-Regular.woff2") format("woff2"), url("./fonts/NeueMachina-Regular.woff") format("woff"), url("./fonts/NeueMachina-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Montreal";
    src: url("./fonts/NeueMontreal-Medium.eot");
    src: url("./fonts/NeueMontreal-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/NeueMontreal-Medium.woff2") format("woff2"), url("./fonts/NeueMontreal-Medium.woff") format("woff"), url("./fonts/NeueMontreal-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Montreal";
    src: url("./fonts/NeueMontreal-Light.eot");
    src: url("./fonts/NeueMontreal-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/NeueMontreal-Light.woff2") format("woff2"), url("./fonts/NeueMontreal-Light.woff") format("woff"), url("./fonts/NeueMontreal-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #e8e8e8;
    font-family: "Neue Machina";
}

p,
span,
li,
label,
button,
input,
a {
    color: #7f7f91;
    font-family: "Neue Montreal";
}

input:focus-visible {
    outline: none;
}