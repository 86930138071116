.hero {
  display: block;
  gap: 20px;
}

.details > img {
  background: #424b5c;
  width: 80px;
  border-radius: 12px;
  filter: drop-shadow(3px 8px 60px rgba(255, 255, 255, 0.25));
}

.details > div {
  display: flex;
  gap: 12px;
  align-items: center;
}

.details > div img {
  width: 20px;
  cursor: pointer;
}

.details > div h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height, or 133% */

  letter-spacing: -0.014em;

  color: #dad7d8;

  text-shadow: 8px 2px 60px rgba(243, 18, 62, 0.8);
}

.row {
  display: grid;
  grid-template-columns: 60% 1fr;
}

.row span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.014em;

  color: #dcdcdc;
}

.row p {
  margin: 10px 0;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.014em;

  color: #ffffff;
}

.row > div > div {
  display: flex;
  align-items: center;
  gap: 7px;
}

.row > div > div img {
  width: 20px;
}

.stats > :first-child {
  margin-bottom: 60px;
}

.menu {
  margin-top: 85px;
  grid-column: 1/3;
  width: 100%;
  border-bottom: 2px solid #543f3f;
  display: flex;
  margin-bottom: 25px;
}

.menu li {
  list-style: none;
  padding: 5px 15px;
  transition: all 300ms ease-in-out;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.014em;
  cursor: pointer;
  color: #7f7f91;
}

.menu li:hover,
.active {
  color: #ffffff !important;
  border-bottom: 2px solid #ffc8c8;
  text-shadow: 2px 3px 8px rgba(243, 18, 62, 0.8);
}

@media only screen and (min-width: 700px) {
  .details,
  .stats {
    width: 420px;
  }

  .stats {
    justify-self: flex-end;
  }
}
@media only screen and (min-width: 1000px) {
  .hero {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 85px !important;
  }
}
