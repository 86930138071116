.chart {
    border-right: 1px solid #543f3f;
    padding: 0 15px;
}

.spark {
    margin-top: 35px;
    width: 100%;
    display: block !important;
}

.chart> :first-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.chart> :first-child> :first-child {
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 10px;
}

.chart> :first-child> :first-child p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */
    margin: 0;
    padding: 10px;
    letter-spacing: -0.014em;
    color: #ffffff;
}

.chart> :first-child> :last-child>span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.price {
    display: flex;
    align-items: center;
    gap: 4px;
}

.price img {
    width: 17px;
}

.price p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */
    margin: 7px 0;
    letter-spacing: -0.014em;
    color: #ffffff;
}

.change {
    display: flex;
    gap: 5px;
}

.change> :last-child {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: -0.014em;
}

.green {
    color: #4dff27;
}

.red {
    color: #ff2727;
}

@media only screen and (min-width: 450px) {
    .spark {
        margin-top: 65px;
        width: 100%;
        max-width: 300px;
    }
}

@media only screen and (min-width: 766px) {
    .chart {
        min-height: 50vh;
    }
}