.none {
    display: none;
}

.items> :first-child {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

.searchBar {
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    max-width: 230px;
    padding: 10px 12px;
}

.searchBar img {
    width: 15px;
}

.searchBar input {
    background-color: transparent;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    width: -webkit-fill-available;
    color: #ffffff;
}

.searchBar input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #ffffff;
}

.priceBar {
    position: relative;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 10px;
    width: 100%;
    max-width: 120px;
    padding: 10px 12px;
}

.priceBar> :first-child {
    display: grid;
    grid-template-columns: 1fr 12px;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.priceBar p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    width: -webkit-fill-available;
    color: #ffffff;
    margin: 0;
}

.priceBar> :first-child img {
    width: 12px;
}

.dropDown {
    position: absolute;
    top: 55px;
    width: 135px;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 8px;
    padding: 3px;
    top: 48px;
    right: 0;
    opacity: 1;
    transition: all 500ms ease-in-out;
    display: none;
}

.dropDown li {
    list-style: none;
    padding: 7px 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.014em;
    width: -webkit-fill-available;
    color: #a7a7a7;
    user-select: none;
    cursor: pointer;
}

.show {
    display: block;
}

.mapCards {
    margin-top: 45px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.empty {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    margin: 3px 0;
    color: #cacaca;
    text-align: center;
}

@media only screen and (min-width: 766px) {
    .items> :first-child {
        justify-content: flex-end;
    }
}