.button {
    background: #0F18CB;
    box-shadow: 0px 0px 45px #5151E5, inset 0px 0px 20px #9E9E9E;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 200% */
    cursor: pointer;
    text-align: center;
    letter-spacing: -0.014em;
    padding: 8px 35px;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    margin-top: 25px;
}

.button::before {
    content: "";
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    transform: translateX(-100%) rotate(45deg);
    transition: all 0.6s;
}

.button:hover::before {
    transform: translateX(100%) rotate(45deg);
}