.items {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 0 0 100px 0;
}

@media only screen and (min-width: 766px) {
  .items {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
}
