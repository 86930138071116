.mint {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1000px;
    margin-top: 5%;
    align-items: center;
    gap: 35px;
}

.ace {
    display: none;
}

.threebrothers {
    display: none;
}

@media only screen and (min-width: 850px) {
    .mint {
        grid-template-columns: 1fr 1fr;
    }
    .ace {
        display: block;
        position: absolute;
        width: 50px;
        left: 10%;
        bottom: 10%;
    }
    .threebrothers {
        display: block;
        position: absolute;
        width: 50px;
        right: 10%;
        top: 20%;
    }
}