.hero {
    margin-top: 55px !important;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
    gap: 20px;
}

.claim,
.stake {
    width: 256px;
    height: 124px;
    left: 97px;
    top: 192px;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 10px;
    padding: 25px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu {
    margin-top: 85px;
    width: 100%;
    border-bottom: 2px solid #543f3f;
    display: flex;
    margin-bottom: 25px;
}

.menu li {
    list-style: none;
    padding: 5px 15px;
    transition: all 300ms ease-in-out;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: -0.014em;
    cursor: pointer;
    color: #7f7f91;
}

.menu li:hover,
.active {
    color: #ffffff !important;
    border-bottom: 2px solid #ffc8c8;
    text-shadow: 2px 3px 8px rgba(243, 18, 62, 0.8);
}

.stake>div>span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.stake>div>div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.stake>div>div span {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.014em;
    color: #7f7f91;
}

.stake>div>div img {
    width: 18px;
}

.stake>div>div p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height */
    margin: 3px 0;
    letter-spacing: -0.014em;
    color: #ffffff;
}

.claim>p {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: +0.014em;
    color: #dcdcdc;
}

.claim>div {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    gap: 6px;
}

.claim>div p {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    margin: 0;
    letter-spacing: -0.014em;
    color: #ffffff;
}

.claim>div span {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;
    /* or 100% */
    letter-spacing: -0.014em;
    color: #7f7f91;
}

.claim>div button {
    background: #0F18CB;
    box-shadow: 0px 0px 45px #5151E5, inset 0px 0px 20px #9E9E9E;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 200% */
    cursor: pointer;
    text-align: center;
    letter-spacing: -0.014em;
    padding: 0 25px;
    color: #ffffff;
    position: relative;
    overflow: hidden;
}

.claim>div button::before {
    content: "";
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    transform: translateX(-100%) rotate(45deg);
    transition: all 0.6s;
}

.claim>div button:hover::before {
    transform: translateX(100%) rotate(45deg);
}

@media only screen and (min-width: 1090px) {
    .hero {
        margin-top: 85px !important;
    }
    .stake {
        justify-self: center;
    }
    .hero> :nth-child(3) {
        justify-self: flex-end !important;
    }
}