.mint {
    max-width: 350px;
    padding: 0 0 55px 0;
}

.mint h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 45px;
    letter-spacing: -0.014em;
    color: #dad7d8;
    margin: 0 0 20px 0;
    text-shadow: 8px 2px 60px rgba(243, 18, 62, 0.8);
}

.mint p {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    /* or 160% */
    letter-spacing: -0.014em;
    color: #7f7f91;
}

.stats {
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
}

.stats>div>span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.stats>div>div,
.tmint>div {
    display: flex;
    gap: 6px;
    align-items: center;
}

.stats>div>div p,
.tmint>div p {
    margin: 12px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #ffffff;
}

.stats>div>div img,
.tmint>div img {
    width: 22px;
}

.large {
    margin-top: 18px;
}

.large button {
    width: 100% !important;
    height: 49px;
}

.input {
    display: grid;
    grid-template-columns: 5px 1fr 5px;
    width: calc(100% - 55px);
    gap: 10px;
    padding: 10px 25px;
    background: #17121d;
    border: 1px solid #543f3f;
    border-radius: 12px;
}

.input p {
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: -0.014em;
    margin: 10px 0;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.input input {
    background-color: transparent;
    border: none;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: 0.014em;
    color: #dcdcdc;
}

.tmint {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.tmint>span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: -0.014em;
    color: #dcdcdc;
}

.mintButton {
    background: #0F18CB;
    box-shadow: 0px 0px 45px #5151E5, inset 0px 0px 20px #9E9E9E;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 200% */
    cursor: pointer;
    text-align: center;
    letter-spacing: -0.014em;
    padding: 4px 28px;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    width: 100% !important;
    height: 49px;
    margin-top: 12px;
}

.mintButton::before {
    content: "";
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    transform: translateX(-100%) rotate(45deg);
    transition: all 0.6s;
}

.mintButton:hover::before {
    transform: translateX(100%) rotate(45deg);
}

.email {
    background: #17121d;
    border: 1px solid #543f3f;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: 0.014em;
    color: #dcdcdc;
    border-radius: 7px;
    padding: 10px 25px;
    width: -webkit-fill-available;
    margin: 12px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.014em;
    color: #cdcdcd;
}

.email::placeholder {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.014em;
    color: #7f7f91;
}

@media only screen and (min-width: 850px) {
    .mint {
        padding: 0;
    }
}